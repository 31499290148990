<template>
    <b-overlay :show="formShow" rounded="sm" no-fade>
        <section class="invoice-add-wrapper">
            <b-row class="invoice-add">
                <!-- Col: Left (Invoice Container) -->
                <b-col cols="12" xl="12" md="8">
                    <validation-observer ref="invoiceRules">
                        <b-form @submit.prevent>
                            <b-card no-body class="invoice-preview-card">
                                <!-- Header -->
                                <b-card-body class="invoice-padding pb-0">
                                    <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
                                        <!-- Header: Left Content -->
                                        <div>
                                            <div class="logo-wrapper">
                                                <logo />
                                            </div>
                                            <p class="card-text mb-25" style="width: 300px;">
                                                Show Attack Ltd
                                            </p>

                                            <p class="card-text mb-25">{{ $func.getName() }}, {{ $func.getStreet() }} <br /></p>
                                            <p class="card-text mb-25">{{ $func.getTown() }} {{ $func.getPostCode() }}, {{ $func.getCountry() }}</p>
                                            <p class="card-text mb-0">
                                                {{ $func.getPhone() }}
                                            </p>
                                        </div>

                                        <b-col cols="12" md="6">
                                            <!-- Header: Right Content -->
                                            <div class="invoice-number-date mt-md-0 mt-2 float-right">
                                                <span class="title">
                                                    Invoice No
                                                </span>
                                                <div>
                                                    <b-input-group class="input-group-merge invoice-edit-input-group disabled">
                                                        <b-form-input style="width:100%; " id="invoice-data-id" v-model="invoiceData.id" disabled />
                                                    </b-input-group>
                                                </div>
                                                <span class="title">
                                                    Date
                                                </span>
                                                <div>
                                                    <validation-provider name="Date" #default="{ errors }" rules="required">
                                                        <flat-pickr
                                                            title="Date"
                                                            style="width:100%; "
                                                            v-model="invoiceData.date"
                                                            :config="{ dateFormat: 'd-m-Y', locale: { firstDayOfWeek: 1 } }"
                                                            class="form-control invoice-edit-input"
                                                        />
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                    </validation-provider>
                                                </div>

                                                <span class="title">
                                                    Due Date:
                                                </span>
                                                <div>
                                                    <validation-provider name="Due Date" #default="{ errors }" rules="required">
                                                        <flat-pickr
                                                            style="width:100%; "
                                                            v-model="invoiceData.dueDate"
                                                            :config="{ dateFormat: 'd-m-Y', locale: { firstDayOfWeek: 1 } }"
                                                            class="form-control invoice-edit-input"
                                                        />
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                    </validation-provider>
                                                </div>
                                            </div>
                                        </b-col>
                                    </div>
                                </b-card-body>

                                <!-- Spacer -->
                                <hr class="invoice-spacing" />

                                <!-- Invoice Client & Payment Details -->

                                <b-card-body class="invoice-padding pt-0">
                                    <b-row class="invoice-spacing">
                                        <!-- Col: Invoice To -->
                                        <b-col cols="12" xl="6" class="mb-lg-1">
                                            <h6 class="mb-2">
                                                Customer
                                            </h6>
                                            <p>{{ eventData.client }}</p>
                                            <p>{{ eventData.client_address }}</p>
                                            <p>{{ eventData.client_vat }}</p>

                                            <!-- Select Client -->

                                            <!-- Selected Client -->
                                        </b-col>
                                    </b-row>
                                </b-card-body>

                                <!-- Items Section -->
                                <b-card-body class="invoice-padding form-item-section">
                                    <!-- div ref="form" class="repeater-form" :style="{ height: trHeight }" -->
                                    <div ref="form" class="repeater-form">
                                        <b-row v-for="(item, index) in invoiceData.items" :key="index" ref="row" class="pb-2">
                                            <!-- Item Form -->
                                            <!-- ? This will be in loop => So consider below markup for single item -->
                                            <b-col cols="12">
                                                <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                                                <div class="d-none d-lg-flex">
                                                    <b-row class="flex-grow-1 px-1">
                                                        <!-- Single Item Form Headers -->
                                                        <b-col cols="12" lg="5">
                                                            Line Item
                                                        </b-col>
                                                    </b-row>
                                                    <div class="form-item-action-col" />
                                                </div>

                                                <!-- Form Input Fields OR content inside bordered area  -->
                                                <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->

                                                <div class="d-flex border rounded">
                                                    <b-row class="flex-grow-1 p-2">
                                                        <!-- Single Item Form Headers -->

                                                        <b-col cols="12" lg="3">
                                                            <span class="mb-2">
                                                                Item
                                                            </span>
                                                            <validation-provider name="Item" #default="{ errors }" rules="required">
                                                                <b-form-input v-model="item.itemTitle" type="text" class="mb-2" />
                                                                <small class="text-danger">{{ errors[0] }}</small>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col cols="12" lg="2">
                                                            <span class="mb-2">
                                                                Cost
                                                            </span>
                                                            <validation-provider name="Cost" #default="{ errors }" rules="required">
                                                                <b-form-input v-model="item.cost" type="number" class="mb-2" />
                                                                <small class="text-danger">{{ errors[0] }}</small>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col cols="12" lg="2">
                                                            <span class="mb-2">
                                                                Quantity
                                                            </span>
                                                            <validation-provider name="Quantity" #default="{ errors }" rules="required">
                                                                <b-form-input v-model="item.qty" type="number" class="mb-2" />
                                                                <small class="text-danger">{{ errors[0] }}</small>
                                                            </validation-provider>
                                                        </b-col>

                                                        <b-col cols="12" lg="2">
                                                            <span class="mb-2">
                                                                Price
                                                            </span>

                                                            <br />
                                                            <span v-if="!item.discount" class="mb-2">
                                                                <strong>{{ item.cost * item.qty }}</strong>
                                                            </span>
                                                            <span v-if="item.discount != null && item.discount > 0" class="mb-2">
                                                                <strong style="text-decoration: line-through;">{{ item.cost * item.qty }}</strong>
                                                                <br />
                                                                <strong>{{ item.cost * item.qty - (item.cost * item.qty * item.discount) / 100 }}</strong>
                                                            </span>
                                                        </b-col>

                                                        <b-col cols="12" lg="5">
                                                            <span class="mb-2">
                                                                Description
                                                            </span>
                                                            <b-form-textarea v-model="item.description" class="mb-2 mb-lg-0" />
                                                        </b-col>
                                                        <b-col cols="12" lg="2">
                                                            <p class="mb-0"><br /></p>
                                                            <p class="mb-0">Discount: {{ item.discount ? item.discount : 0 }} %</p>
                                                            <p class="mb-0">PST: {{ item.pst ? item.pst : 0 }} %</p>
                                                            <p class="mb-0">GST: {{ item.gst ? item.gst : 0 }} %</p>
                                                        </b-col>
                                                    </b-row>
                                                    <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                                                        <feather-icon size="16" icon="XIcon" class="cursor-pointer" @click="removeItem(index)" />
                                                        <feather-icon :id="`form-item-settings-icon-${index}`" size="16" icon="SettingsIcon" class="cursor-pointer" />

                                                        <!-- Setting Item Form -->
                                                        <b-popover :ref="`form-item-settings-popover-${index}`" :target="`form-item-settings-icon-${index}`" triggers="click" placement="lefttop">
                                                            <b-form @submit.prevent>
                                                                <b-row>
                                                                    <!-- Field: Discount -->
                                                                    <b-col cols="12">
                                                                        <b-form-group label="Discount(%)" :label-for="`setting-item-${index}-discount`">
                                                                            <cleave
                                                                                id="options"
                                                                                style="height:2.142rem"
                                                                                v-model="item.discount"
                                                                                class="form-control"
                                                                                :raw="true"
                                                                                :options="options.percent"
                                                                            />
                                                                        </b-form-group>
                                                                    </b-col>

                                                                    <!-- Field: Tax 1 -->
                                                                    <b-col cols="6">
                                                                        <b-form-group label="GST(%)" :label-for="`setting-item-${index}-tax-1`">
                                                                            <cleave
                                                                                id="options"
                                                                                style="height:2.142rem"
                                                                                v-model="item.gst"
                                                                                class="form-control"
                                                                                :raw="true"
                                                                                :options="options.percent"
                                                                            />
                                                                        </b-form-group>
                                                                    </b-col>

                                                                    <!-- Field: Tax 2 -->
                                                                    <b-col cols="6">
                                                                        <b-form-group label="PST(%)" :label-for="`setting-item-${index}-tax-2`">
                                                                            <cleave
                                                                                id="options"
                                                                                style="height:2.142rem"
                                                                                v-model="item.pst"
                                                                                class="form-control"
                                                                                :raw="true"
                                                                                :options="options.percent"
                                                                            />
                                                                        </b-form-group>
                                                                    </b-col>

                                                                    <b-col cols="12" class="d-flex justify-content-between mt-1">
                                                                        <b-button
                                                                            variant="outline-secondary"
                                                                            @click="
                                                                                () => {
                                                                                    $refs[`form-item-settings-popover-${index}`][0].$emit('close');
                                                                                }
                                                                            "
                                                                        >
                                                                            Close
                                                                        </b-button>
                                                                    </b-col>
                                                                </b-row>
                                                            </b-form>
                                                        </b-popover>
                                                    </div>
                                                </div>
                                            </b-col>
                                        </b-row>
                                    </div>
                                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="sm" variant="primary" @click="addNewItemInItemForm">
                                        Add Item
                                    </b-button>
                                </b-card-body>

                                <!-- Invoice Description: Total -->
                                <b-card-body class="invoice-padding pb-0">
                                    <b-row>
                                        <!-- Col: Sales Persion -->

                                        <!-- Col: Total -->
                                        <b-col cols="12" md="12" class="mt-md-6 d-flex justify-content-end" order="1" order-md="2">
                                            <div class="invoice-total-wrapper">
                                                <div class="invoice-total-item">
                                                    <p class="invoice-total-title">
                                                        Subtotal:
                                                    </p>
                                                    <p class="invoice-total-amount">{{ $func.formatPrice(subTotal) }}</p>
                                                </div>
                                                <div class="invoice-total-item">
                                                    <p class="invoice-total-title">
                                                        Discount:
                                                    </p>
                                                    <p class="invoice-total-amount">{{ $func.formatPrice(totalDiscount) }}</p>
                                                </div>
                                                <div class="invoice-total-item">
                                                    <p class="invoice-total-title">
                                                        Tax:
                                                    </p>
                                                    <p class="invoice-total-amount">{{ $func.formatPrice(totalTax) }}</p>
                                                </div>
                                                <hr class="my-50" />
                                                <div class="invoice-total-item">
                                                    <p class="invoice-total-title">
                                                        Total:
                                                    </p>
                                                    <p class="invoice-total-amount">{{ $func.formatPrice(total) }}</p>
                                                </div>
                                            </div>
                                        </b-col>
                                    </b-row>
                                </b-card-body>

                                <!-- Spacer -->
                                <hr class="invoice-spacing" />

                                <!-- Note -->
                                <b-card-body class="invoice-padding pt-0">
                                    <span class="font-weight-bold">Note: </span>
                                    <b-form-textarea v-model="invoiceData.note" />
                                </b-card-body>
                                <b-card-body class="invoice-padding pt-0">
                                    <b-col cols="12">
                                        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" @click="saveInvoice" variant="primary" class="mr-1 float-right">
                                            Save
                                        </b-button>
                                    </b-col>
                                </b-card-body>
                            </b-card>
                        </b-form>
                    </validation-observer>
                </b-col>
            </b-row>
        </section>
    </b-overlay>
</template>

<script>
import Logo from "@core/layouts/components/Logo.vue";
import { ref, onUnmounted } from "@vue/composition-api";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import { BRow, BCol, BCard, BCardBody, BButton, BCardText, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, BFormTextarea, BFormCheckbox, BPopover, VBToggle } from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import eventStoreModule from "../eventStoreModule";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Cleave from "vue-cleave-component";
import "cleave.js/dist/addons/cleave-phone.us";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";

export default {
    components: {
        BRow,
        BCol,
        BCard,
        BCardBody,
        BButton,
        BCardText,
        BForm,
        BFormGroup,
        BFormInput,
        BInputGroup,
        BInputGroupPrepend,
        BFormTextarea,
        BFormCheckbox,
        BPopover,
        flatPickr,
        vSelect,
        Logo,
        ToastificationContent,
        Cleave,
        ValidationProvider,
        ValidationObserver,
    },

    props: {
        eventData: {
            type: Object,
            required: false,
        },
    },

    data() {
        return {
            changed: false,

            selectedPst: null,
            selectedGst: null,
            selectedDiscount: null,
            filter: null,
            filterOn: [],
            perPage: localStorage.getItem("customerShow") ?? 10,
            itemFormBlankItem: {
                cost: null,
                qty: null,
                description: null,
                inventoryId: null,
                gst: null,
                pst: null,
                discount: null,
            },
            invoiceData: {
                items: [
                    {
                        itemTitle: null,
                        cost: null,
                        qty: null,
                        description: null,
                        gst: null,
                        pst: null,
                        discount: null,
                    },
                ],
                event_id: this.eventData.id,
                customerId: null,
                id: null,
                date: null,
                dueDate: null,
                note: null,
                status: null,
            },

            currentPage: 1,
            perPageOptions: [10, 25, 50, 100],
            colWidthFactor: null,
            totalRows: 0,
            baseURL: store.state.app.baseURL,
            formShow: false,

            statusType: null,
            status: null,

            options: {
                number: {
                    numeral: true,
                    numeralThousandsGroupStyle: "thousand",
                    numeralPositiveOnly: true,
                },

                percent: {
                    numeral: true,
                    numeralPositiveOnly: true,
                    blocks: [2],
                    prefix: "% ",
                    rawValueTrimPrefix: true,
                    numeralIntegerScale: 2,
                },
            },
            required,
            email,
        };
    },

    directives: {
        Ripple,
        "b-toggle": VBToggle,
    },

    computed: {
        subTotal() {
            var result = 0;
            this.invoiceData.items.forEach((element) => {
                result = result + element.cost * element.qty;
            });
            return result;
        },

        totalDiscount() {
            var result = 0;
            this.invoiceData.items.forEach((element) => {
                result += (element.cost * element.qty * element.discount) / 100;
            });
            return result;
        },

        totalTax() {
            var result = 0;

            var gst = 0;
            var pst = 0;
            this.invoiceData.items.forEach((element) => {
                if (element.gst != null && element.gst != "" && element.gst != 0) {
                    gst = (element.cost * element.qty * parseFloat(element.gst)) / 100;
                }

                if (element.pst != null && element.pst != "" && element.pst != 0) {
                    pst = (element.cost * element.qty * parseFloat(element.pst)) / 100;
                }

                result = result + gst + pst;

                gst = 0;
                pst = 0;
            });
            return result ? result : 0;
        },

        total() {
            var result = this.subTotal - this.totalDiscount + this.totalTax;

            return result ? result : 0;
        },
    },

    watch: {
        "invoiceData.items": {
            handler: function(val, before) {
                if (val != null || val != "None") {
                    if (this.invoiceData.id != null && before[0].itemTitle != null && before[0].itemCategory != null && before[0].cost != null) {
                        this.invoiceData.changed = true;
                    }
                }
            },
        },

        "eventData.cost_list": {
            handler: function(val, before) {},
            deep: true,
        },
    },

    methods: {
        getEventById() {
            this.formShow = true;

            if (router.currentRoute.params.id) {
                this.eventId = parseInt(router.currentRoute.params.id);

                store
                    .dispatch("event/fetchEvent", { id: router.currentRoute.params.id })
                    .then((response) => {
                        (this.relatedCosts = []), (this.eventData = response.data);

                        response.data.cost_list.forEach((element) => {
                            this.cost_list.push(element);
                        });

                        this.events.forEach((element) => {
                            element.cost_list.forEach((cost) => {
                                this.relatedCosts.push(cost);
                            });
                        });

                        this.events = this.events.filter((item) => item.id != this.eventData.id);

                        this.formShow = false;
                    })
                    .catch((error) => {
                        console.log(error);
                        this.formShow = false;
                    });
            } else {
                this.formShow = false;
            }
        },

        addNewItemInItemForm() {
            this.$refs.form.style.overflow = "hidden";
            this.invoiceData.items.push(JSON.parse(JSON.stringify(this.itemFormBlankItem)));

            this.$nextTick(() => {
                setTimeout(() => {
                    this.$refs.form.style.overflow = null;
                }, 350);
            });
        },
        removeItem(index) {
            this.invoiceData.items.splice(index, 1);
            //this.trTrimHeight(this.$refs.row[0].offsetHeight);
            this.invoiceData.changed = true;
        },

        createInvoice() {
            this.eventData.cost_list.forEach((element) => {
                if (element.invoice == 1) {
                    this.invoiceData.items.push(
                        JSON.parse(
                            JSON.stringify({
                                itemTitle: element.description,
                                cost: element.cost,
                                qty: 1,
                                description: null,
                                gst: null,
                                pst: null,
                                discount: null,
                            })
                        )
                    );
                }
                if (this.invoiceData.items.length > 1) {
                    this.invoiceData.items = this.invoiceData.items.filter((item) => item.itemTitle != null);
                }

                this.formShow = false;
            });
        },

        saveInvoice() {
            this.formShow = true;
            if (this.invoiceData.items.length > 0) {
                this.$refs.invoiceRules.validate().then((success) => {
                    if (success) {
                        if (router.currentRoute.params.id) {
                            store
                                .dispatch("event/saveInvoice", this.invoiceData)
                                .then((response) => {
                                    this.$toast({
                                        component: ToastificationContent,
                                        props: {
                                            title: "Successful",
                                            text: "✔️ Entry has been saved successfully",
                                            icon: "ThumbsUpIcon",
                                            variant: "success",
                                        },
                                    });

                                    if (this.$Can("order_show")) {
                                        router.push({ name: "income-preview", params: { id: response.data.id } });
                                    } else {
                                        router.push({ name: "purchase-order" });
                                    }
                                })
                                .catch((e) => {
                                    this.formShow = false;

                                    this.$swal({
                                        title: "Error",
                                        text: "Process Failed. Please try again or contact support.",
                                        icon: "error",
                                        showCancelButton: false,
                                        confirmButtonText: "OK",
                                    });
                                });
                        } else {
                            store
                                .dispatch("event/saveSaleOrder", this.invoiceData)
                                .then((response) => {
                                    if (response.status == 201) {
                                        this.$toast({
                                            component: ToastificationContent,
                                            props: {
                                                title: "Successful",
                                                text: "✔️ Entry has been saved successfully",
                                                icon: "ThumbsUpIcon",
                                                variant: "success",
                                            },
                                        });

                                        this.formShow = false;
                                        if (this.$Can("order_show")) {
                                            router.push({ name: "income-preview", params: { id: response.data.id } });
                                        } else {
                                            router.push({ name: "purchase-order" });
                                        }
                                    }
                                })
                                .catch((e) => {
                                    this.formShow = false;

                                    this.$swal({
                                        title: "Error",
                                        text: "Process Failed. Please try again or contact support.",
                                        icon: "error",
                                        showCancelButton: false,
                                        confirmButtonText: "OK",
                                    });
                                });
                        }
                    } else {
                        this.formShow = false;
                    }
                });
            } else {
                this.formShow = false;

                this.$swal({
                    title: "Error",
                    text: "Process Failed. Please try again or contact support.",
                    icon: "error",
                    showCancelButton: false,
                    confirmButtonText: "OK",
                });
            }
        },

        approveInvoice() {
            this.formShow = true;
            if (this.invoiceData.items.length > 0) {
                this.$refs.invoiceRules.validate().then((success) => {
                    if (success) {
                        if (router.currentRoute.params.id) {
                            store
                                .dispatch("event/saveInvoice", this.invoiceData)
                                .then((response) => {
                                    this.$toast({
                                        component: ToastificationContent,
                                        props: {
                                            title: "Successful",
                                            text: "✔️ Entry has been saved successfully",
                                            icon: "ThumbsUpIcon",
                                            variant: "success",
                                        },
                                    });

                                    if (this.$Can("order_show")) {
                                        router.push({ name: "income-preview", params: { id: response.data.id } });
                                    } else {
                                        router.push({ name: "purchase-order" });
                                    }
                                })
                                .catch((e) => {
                                    this.formShow = false;

                                    this.$swal({
                                        title: "Error",
                                        text: "Process Failed. Please try again or contact support.",
                                        icon: "error",
                                        showCancelButton: false,
                                        confirmButtonText: "OK",
                                    });
                                });
                        } else {
                            store
                                .dispatch("event/saveSaleOrder", this.invoiceData)
                                .then((response) => {
                                    if (response.status == 201) {
                                        this.$toast({
                                            component: ToastificationContent,
                                            props: {
                                                title: "Successful",
                                                text: "✔️ Entry has been saved successfully",
                                                icon: "ThumbsUpIcon",
                                                variant: "success",
                                            },
                                        });

                                        this.formShow = false;
                                        if (this.$Can("order_show")) {
                                            router.push({ name: "income-preview", params: { id: response.data.id } });
                                        } else {
                                            router.push({ name: "purchase-order" });
                                        }
                                    }
                                })
                                .catch((e) => {
                                    this.formShow = false;
                                    this.$swal({
                                        title: "Error",
                                        text: "Process Failed. Please try again or contact support.",
                                        icon: "error",
                                        showCancelButton: false,
                                        confirmButtonText: "OK",
                                    });
                                });
                        }
                    } else {
                        this.formShow = false;
                    }
                });
            } else {
                this.formShow = false;

                this.$swal({
                    title: "Error",
                    text: "Process Failed. Please try again or contact support.",
                    icon: "error",
                    showCancelButton: false,
                    confirmButtonText: "OK",
                });
            }
        },

        getByIdIncome() {
            if (router.currentRoute.params.id) {
                store
                    .dispatch("event/fetchIncomeById", { id: router.currentRoute.params.id })
                    .then((response) => {
                        const d = new Date(response.data.date);
                        const f = new Date(response.data.dueDate);

                        response.data.date = d;
                        response.data.dueDate = f;
                        this.invoiceData = response.data;

                        var index = 0;
                        response.data.items.forEach((itemCategory) => {
                            this.dropItems[index] = this.dropCategories.find((x) => x.id == itemCategory.itemCategory).item;

                            index++;
                        });
                        this.formShow = false;
                    })
                    .catch((error) => {
                        console.log(error);
                        this.formShow = false;

                        this.$swal({
                            title: "Error",
                            text: "Process Failed. Please try again or contact support.",
                            icon: "error",
                            showCancelButton: false,
                            confirmButtonText: "OK",
                        });

                        // router.push({ name: 'quote-list' });
                    });
            } else {
                this.formShow = false;
            }
        },

        printInvoice(val) {
            this.download = true;
            this.formShow = true;
            store.dispatch("event/printInvoice", val).then((response) => {
                if (response.status == 200) {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", "Invoice_" + Number(val) + ".pdf");
                    document.body.appendChild(link);
                    link.click();
                    this.formShow = false;

                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Successful",
                            text: "✔️ Sale Order is created!",
                            icon: "ThumbsUpIcon",
                            variant: "success",
                        },
                    });
                    this.download = false;
                }
            });
        },
    },
    setup() {
        const EVENT_APP_STORE_MODULE_NAME = "event";

        // Register module
        if (!store.hasModule(EVENT_APP_STORE_MODULE_NAME)) store.registerModule(EVENT_APP_STORE_MODULE_NAME, eventStoreModule);

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(EVENT_APP_STORE_MODULE_NAME)) store.unregisterModule(EVENT_APP_STORE_MODULE_NAME);
        });
    },
    created() {
        this.formShow = true;
        this.createInvoice();
        // this.getCustomerDrops();
        // this.getInventoryDrops();
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.invoice-add-wrapper {
    .add-new-client-header {
        padding: $options-padding-y $options-padding-x;
        color: $success;

        &:hover {
            background-color: rgba($success, 0.12);
        }
    }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";

.form-item-section {
    background-color: #fff;
}

.form-item-action-col {
    width: 27px;
}

.repeater-form {
    // overflow: hidden;
    transition: 0.35s height;
}

.v-select {
    &.item-selector-title,
    &.payment-selector {
        background-color: #fff;

        .dark-layout & {
            background-color: unset;
        }
    }
}

.dark-layout {
    .form-item-section {
        background-color: $theme-dark-body-bg;

        .row .border {
            background-color: $theme-dark-card-bg;
        }
    }
}
</style>
