import axiosIns from '@/libs/axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`user/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    eventShow(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`event/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getEventDropdownList(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('getEventDropdownList')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    eventSave(ctx, eventData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('saveEvent', eventData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    eventPoNumberCheck(ctx, eventData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('poNumberCheck', eventData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    eventGroupPoNumberCheck(ctx, eventData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('eventGroupPoNumberCheck', eventData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },



    

    addHotel(ctx, hotelData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('hotel', hotelData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    eventUpdate(ctx, eventData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('updateEvent', eventData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    duplicateEvent(ctx, duplicateData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('duplicateEvent', duplicateData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateCost(ctx, costData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put('updateCost', costData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchEvent(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`event2/${id}`)
          .then((response) => {
            if (response.data == 'Error') {
              resolve('Error');
            } else {
              if (response.data.status == 'P') {
                response.data.status = 'passive';
              } else if (response.data.status == 'D') {
                response.data.status = 'deleted';
              } else {
                response.data.status = 'active';
              }

              resolve(response);
            }
          })
          .catch((error) => reject(error));
      });
    },

    saveInvoice(ctx, invoiceData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('saveInvoice', invoiceData)
          .then((response) => {
            resolve(response);
            //     showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    updateIncome(ctx, params) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`updateIncome`, params)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    printInvoice(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('printInvoice', { id: 1 }, { responseType: 'blob' })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getTimeline(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('timeline')
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    employeeAvailability(ctx, tempData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`employeeAvailabilityEvent/${tempData.userId}`, tempData)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },
  },
};
